<script setup lang="ts">
/**
 * A region placed at the top of each page to make it draggable by tauri
 */

// On browser, we want to ignore this drag region entirely, as it interferes with
// things like right-click handlers
const ignore = ref(false)

// We need to set the ignore var on mounted as it will be unset in SSR mode.
onMounted(() => ignore.value = isBrowser())
</script>

<template>
  <div
    class="drag"
    :class="{ ignore }"
    data-tauri-drag-region
  />
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.drag
  @apply fixed left-0 right-0 top-0 h-11 w-full z-title-bar-layer
  app-region: drag
.ignore
  @apply pointer-events-none
</style>
